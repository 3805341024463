import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ title, description, image, pathname, article }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultImage,
          twitterUsername
        }
      }
    }) => {
      console.log(pathname)
      if (pathname[0] !== '/') pathname = '/' + pathname
      if (pathname[pathname.length - 1] !== '/') pathname += '/'
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        image: `${siteUrl}${`${pathname}twitter-image.jpg` || defaultImage}`,
        url: `${siteUrl}${pathname || '/'}`
      }
      console.log(seo)
      return (
        <>
          <Helmet title={seo.title} titleTemplate={titleTemplate}>
            <meta name='description' content={seo.description} />
            <meta name='image' content={seo.image} />
            <meta name='monetization' content='$ilp.uphold.com/3aKNJEKMpMNd' />
            {seo.url && <meta property='og:url' content={seo.url} />}
            {(article ? true : null) && (
              <meta property='og:type' content='article' />
            )}
            {seo.title && <meta property='og:title' content={seo.title} />}
            {seo.description && (
              <meta property='og:description' content={seo.description} />
            )}
            {seo.image && <meta property='og:image' content={seo.image} />}
            {twitterUsername && (
              <meta name='twitter:creator' content={twitterUsername} />
            )}
            {seo.title && <meta name='twitter:title' content={seo.title} />}
            {seo.description && (
              <meta name='twitter:description' content={seo.description} />
            )}
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image' content={seo.image} />
          </Helmet>
        </>
      )
    }}
  />
)
export default SEO
SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool
}
SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  pathname: 'home',
  article: false
}
const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultImage: image
        twitterUsername
      }
    }
  }
`
